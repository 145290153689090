@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  custom */

// Extra small devices & Small devices
.ant-descriptions-item-label {
  width: 20%;
  word-break: break-all;
}

.ant-descriptions-item-content {
  width: 80%;
  word-break: break-all;
}

.note-html {
  line-height: 13px;
}

.print-logo {
  width: 130px;
  float: left;
  margin-right: -130px;
}

.print-table tr.ant-table-expanded-row {
  background: none;
}

// print page
.print-col-4 .ant-descriptions-item-label {
  width: 120px;
  max-width: 120px;
  word-break: break-all;
  font-size: 16px;
  padding: 4px 8px !important;
}

.print-col-4 .ant-descriptions-item-content {
  width: 25%;
  max-width: 25%;
  word-break: break-all;
  font-size: 16px;
  padding: 4px 8px !important;
}

// print page
.print-col-2 .ant-descriptions-item-label {
  width: 120px;
  max-width: 120px;
  word-break: break-all;
  font-size: 16px;
  padding: 4px 8px !important;
}

.print-col-2 .ant-descriptions-item-content {
  width: 45%;
  word-break: break-all;
  font-size: 16px;
  padding: 4px 8px !important;
}

@media print {
  .print-form,
  .print-form table {
    color: black;
  }
}

.ql-editor {
  white-space: normal !important;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .ant-descriptions-item-label {
    width: 15%;
    word-break: break-all;
  }

  .ant-descriptions-item-content {
    width: 35%;
    word-break: break-all;
  }
}
